import { memo } from 'react'
 const Modal = memo(({ children, onClose }) => {
  return (
    <div
      className='fixed left-0 top-0 z-10 flex h-full w-full items-center justify-center overflow-auto bg-gray-900 bg-opacity-50'
      onClick={onClose}
    >
      <div
        className='relative inline-block origin-top-left rounded-lg bg-white p-5 shadow-xl w-full sm:w-1/2 md:w-2/3 lg:w-1/3'
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  )
})


export default Modal