import useModal from "./ModalProvider";
import ModalContent from "./ModalContent";
import SecretBlock from "./secretBlock";

const Article = () => {
  return (
    <div>
      <img src={"image1.png"} className={"mx-auto w-full"} alt={"post image5"}/>
      <div>
        <p>
          「これまで何回もウェビナーをしてきたけど、本当のところ何が正解か分からない。」<br/>
          という声をよく聞きます。
        </p>
        <p>
          何百人と集客できてて、ある程度うまくいっているように感じるものの本当にそれは成功なのでしょうか？
        </p>
        <p>
          弊社はこれまで、10業種20社以上のウェビナーのご支援に携わってきました。各業界業種により、ウェビナーの戦略が全く持って異なることも知る事ができました。
        </p>
      </div>
      <div className={"bg-gray-200 p-3 font-bold mt-8 text-2xl"}>
        こんな方におすすめ
      </div>
      <div>
        <p>
          ・社内でのウェビナーの位置付けや目的が曖昧<br/>
          ・ウェビナーを片手間でやっており専任担当がいない<br/>
          ・ウェビナーを半年以上しているが意図した成果が出ない<br/>
          ・配信方法や広告ばかりに拘り、本質的な企画に向き合えていない<br/>
          ・自社や自分にはウェビナーが向いていないのでは？と思っている方<br/>
          ・ウェビナーでリード獲得やナーチャリングを主軸にしたいが効果が出ない
        </p>
      </div>
      <div className={"bg-gray-200 p-3 font-bold mt-8 text-2xl"}>
        イベント内容(要約)
      </div>
      <div>
        <p>
          ターゲティングの違い。費用対効果の違い。網羅的に並べた動画って多分ないんじゃないでしょうか。よろしくお願いします。
        </p>
        <p>
          今日のお話なんですけども、題して主要五大広告徹底比較どれをやるべきか、使い方をプロが解説というお話していきたいなと思います。
        </p>
        <p>
          もともと私自身がその広告代理店におりました。その時の知見をもとに、皆さんのデジタルマーケティングを一つにアップデートするというミッションの元更新しております。
        </p>
        <p>
          拡散性はどうなのか、ターゲッティング機能まいわゆるセグメント機能はどんなことができるのか、費用対効果はどうかというところをお話をしていきたいなと思っています。
        </p>
      </div>
      <div className={"mt-8 flex gap-2"}>
        <div className={"w-2 bg-webinal-green rounded"}></div>
        <h2 className={"font-bold text-3xl"}>拡散性の違い、もしくは関係性の違い</h2>
      </div>
      <img src={"image2.png"} className={"mx-auto mt-4 w-full"} alt={"post image6"}/>
      <div>
        <p>
          こちらのグラフを見てみてください。拡散性の違い、もしくは関係性の違いをグラフに配置してみました。
        </p>
        <p>
          縦軸に拡散性を設定し、上に行けば行くほど高く、下に行けば行くほど低くなります。横軸には関係性を配置し、左はリアルの知り合い、右はオンラインの知り合いを示しています。
        </p>
        <p>
          左側には、FacebookやLINEがあります。これらは、実際にリアルで知り合っている人との連絡手段です。一方、右上にはTikTokなど、オンライン上で仲が良い友達が多いSNSがあります。LINEやYouTubeは、ソーシャルでの拡散性が低い位置に配置されています。TikTokなどのオープンなSNSは、拡散性が高いです。Instagramは、シェア文化よりもフォローや「いいね」に重きを置くため、中盤に位置づけました。
        </p>
        <p>
          御社が展開しようとしている広告が非常に話題性がある場合、<SecretBlock>一方通行の広告配信だけでなく、拡散性が高いSNSを選ぶのが良いでしょう。特に、TikTokとTwitterはシェアされることを目指して広告を作ると拡散効果が高まります。</SecretBlock>
        </p>
        <p>
          広告のターゲットを考える際、興味が繋がっている群にアプローチするのか、ビジネス上の特定の業界に向けるのかによって、使うSNSが異なります。これらのポイントを参考にして、最適な広告戦略を立ててください。
        </p>
      </div>
      <div className={"mt-8 flex gap-2"}>
        <div className={"w-2 bg-webinal-green rounded"}></div>
        <h2 className={"font-bold text-3xl"}>各SNSのターゲティング機能の違い</h2>
      </div>
      <img src={"image3.png"} className={"mx-auto mt-4 w-full"} alt={"post image7"}/>
      <div>
        <p>
          次に五つの中からプロモーションを進めていきましょう。広告を打つ際には、ターゲティング広告になりますので、セグメントを知ることが重要です。こちらにターゲティング機能の違いを一覧表にしましたので、ご覧ください。
        </p>
        <p>
          地域、年齢、性別、子供の有無、年収、興味、関心、職種などのセグメントについて記載しています。それぞれのプラットフォームで可能なターゲティング方法もまとめています。
        </p>
        <p>
          YouTube広告、Facebook広告、Instagram広告は、<SecretBlock>半径何キロ圏内といった</SecretBlock>ターゲティングが可能ですが、Twitter広告やLINE広告は市単位で展開できます。一方、TikTok広告は都道府県単位でしかセグメントできません。
        </p>
        <p>
          年齢、性別、興味、関心はどのプラットフォームでもセグメント可能です。しかし、子供の有無をターゲティングできるのは、LINE広告、Facebook、Instagram、YouTube広告です。年収に関しても、YouTube、Facebook、Instagram広告でターゲティングが可能です。
        </p>
        <SecretBlock>
        <p>
          また、職種については、Facebook広告が特に有効です。Facebookはビジネス情報を登録しているユーザーが多く、業界や役職でのターゲティングが可能です。Instagram広告もFacebookと連携しているため、同様のターゲティングが可能です。
        </p>
        <p>
          Twitter広告では、フォロワーの類似ユーザーをターゲティングすることができます。LINEも同様に、自社アカウントの友達と繋がっているユーザーに対するターゲティング広告が可能です。
        </p>
        <p>
          これらのターゲティング手法の違いを参考に、適切な広告戦略を立ててください。例えば、市単位の商圏を狙う場合や、小さいお子様がいる家庭をターゲティングしたい場合には、特定のプラットフォームが適しています。ぜひ参考にしてほしいと思います。
        </p>
        </SecretBlock>
      </div>
      <div className={"mt-8 flex gap-2"}>
        <div className={"w-2 bg-webinal-green rounded"}></div>
        <h2 className={"font-bold text-3xl"}>費用対効果の違い</h2>
      </div>
      <img src={"image4.png"} className={"mx-auto mt-4 w-full mosaic"} alt={"post image8"}/>
      <div>
        <p>
          次に、費用対効果の違いについてお話しします。こちらの表をご覧ください。
        </p>
        <p>
          これは当社の実績データです。過去の取引企業の全業界、全エリアの平均値ですので、非常に参考になると思います。ただし、当社の成果が良いため、あくまで参考値としてご留意ください。
        </p>
        <p>
          表には、表示単価（CPM）、クリック率（CTR）、クリック単価（CPC）、コンバージョン率、コンバージョン単価などを各SNSごとに並べています。
        </p>
        <p>
          例えば、表示単価に関しては、<SecretBlock>TikTok広告</SecretBlock>が最も優秀で、<SecretBlock>Facebook広告</SecretBlock>はやや割高です。一方、クリック率では<SecretBlock>Facebook広告</SecretBlock>が最も高くなっています。CPCでは、<SecretBlock>TikTok広告が19円と</SecretBlock>最も優秀です。ただし、CPCは業界や運用者によって変動することがあります。特に、慣れていない業者の場合、LINE広告などで高くなることがあります。
        </p>
        <p>
          コンバージョン率とコンバージョン単価については、どのアクションをコンバージョンとするかで大きく変わります。オンライン購買、問い合わせ、来場予約、資料請求など、様々な定義がありますので、ざっくりとした参考値としてご覧ください。インスタグラムや<SecretBlock>Facebook広告は比較的優秀で、</SecretBlock>次いで<SecretBlock>LINE広告やTikTok広告が</SecretBlock>続きます。
        </p>
        <p>
          ただし、これだけでTwitter広告が悪いとは言えません。あくまで参考値としてご理解ください。費用対効果を検討する際には、自社のターゲット層やサービスとの相性、表現のしやすさなども考慮してください。これにより、適切な広告選択が見えてくると思います。
        </p>
        <p>
          以上、今日の動画で紹介したデータを参考にして、広告戦略を立ててください。
        </p>
      </div>
    </div>
  )
}

export default Article;