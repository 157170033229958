import './App.css';
import Article from "./article";
import Register from "./register";
import {Helmet} from "react-helmet-async";

function App() {
  return (
    <div className={"bg-[#F6F8F8]"}>
      <Helmet>
        <title>ウェビナル</title>
      </Helmet>
      <div className="sticky top-0 h-fit md:h-20 border-b shadow bg-white z-10">
        <div className={"py-4"}>
          <img className={"mx-auto block w-8 md:w-fit"} src={"/logo.svg"} alt={"site image1"}/>
        </div>
      </div>
      <div className={"bg-red-400 text-center text-xs top-0 py-1 text-white"}>
        デモページです。コンテンツの内容はダミーです。機能も実装中や制限中になります。
      </div>
      <div className="px-4 container lg:max-w-screen-lg mx-auto my-4 min-h-screen">
        <div className={"mt-6 md:mt-12"}>
          <div className={"flex gap-4"}>
            <div className={"w-2 bg-webinal-green rounded"}></div>
            <h1
              className={"text-xl md:text-4xl font-bold text-webinal-black"}>【SNS広告徹底比較】Webマーケ企業の運用生データを大公開</h1>
          </div>
          <p className={"text-webinal-black2"}>開催日： 2024/05/01</p>
        </div>
        <div className={"flex flex-col-reverse md:flex-row gap-4 mt-4 md:mt-8"}>
          <div className={"w-full md:w-2/3"}>
            <div className={"rounded bg-white p-4"}>
              スピーカー
              <div className={"flex flex-col gap-4"}>
                <div className={"flex gap-4"}>
                  <img src={"/user1.png"} className={"rounded-full w-12 h-12"} alt={"post image2"}/>
                  <div>
                    <p className={"font-bold"}>山田太郎</p>
                    <p>株式会社Webinal</p>
                  </div>
                </div>
                <div className={"flex gap-4"}>
                  <img src={"/user2.png"} className={"rounded-full w-12 h-12"} alt={"post image3"}/>
                  <div>
                    <p className={"font-bold"}>山田 マーケティング</p>
                    <p>株式会社Webinal マーケティング担当</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={"mt-8 rounded p-4 bg-white"} id={"webinar-article"}>
              <Article/>
            </div>
          </div>
          <div className={"flex-auto"}>
            <div className={"rounded bg-white p-8 sticky top-24"}>
              <Register/>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24 h-20 bg-white">
        <div className={"py-4"}>
          <img className={"mx-auto"} src={"/logo.svg"} alt={"image4"}/>
        </div>
      </div>
    </div>
  );
}

export default App;
