const Register = () => {
  return (
    <>
      <p className={"text-center text-3xl text-webinal-green font-bold"}>
        気になる部分を<br/>
        担当者から聞く
      </p>
      <div className={"mt-4"}>
        <div className="flex sm:items-center mb-6 flex-col sm:flex-row">
          <label
            className="block sm:w-1/3 font-bold sm:text-right mb-1 pr-4"
            htmlFor="company"
          >会社名 <span className="text-red-600"> * </span></label
          ><input
          className="block w-full sm:w-2/3 bg-gray-200 py-2 px-3 text-gray-700 border border-gray-200 rounded focus:outline-none focus:bg-white"
          type="text"
          id="email"
          placeholder="株式会社▽▽▽"
        />
        </div>
        <div className="flex sm:items-center mb-6 flex-col sm:flex-row">
          <label
            className="block sm:w-1/3 font-bold sm:text-right mb-1 pr-4"
            htmlFor="name"
          >お名前 <span className="text-red-600"> * </span></label
          ><input
          className="block w-full sm:w-2/3 bg-gray-200 py-2 px-3 text-gray-700 border border-gray-200 rounded focus:outline-none focus:bg-white"
          type="text"
          id="name"
          placeholder="山田太郎"
        />
        </div>
        <div className="flex sm:items-center mb-6 flex-col sm:flex-row">
          <label
            className="block sm:w-1/3 font-bold sm:text-right mb-1 pr-4"
            htmlFor="email"
          >メールアドレス <span className="text-red-600"> * </span></label
          ><input
          className="block w-full sm:w-2/3 bg-gray-200 py-2 px-3 text-gray-700 border border-gray-200 rounded focus:outline-none focus:bg-white"
          type="email"
          id="email"
          placeholder="yamada@example.com"
        />
        </div>
        <div className="flex sm:items-center mb-6 flex-col sm:flex-row">
          <label
            className="block sm:w-1/3 font-bold sm:text-right mb-1 pr-4"
            htmlFor="tel"
          >電話番号<span className="text-red-600"> * </span></label
          ><input
          className="block w-36 bg-gray-200 py-2 px-3 text-gray-700 border border-gray-200 rounded focus:outline-none focus:bg-white"
          type="text"
          id="tel"
          placeholder="00000000000"
        />
        </div>
      </div>
      <div className="flex justify-center">
        <button
          className="bg-webinal-green text-white font-bold py-2 px-4 rounded fucus:outline-none focus:shadow-outline mt-3"
        >
          <span className={"text-xs"}>プライバシーポリシーに同意して<br/></span>
          <span className={"text-2xl"}>送信する</span>
        </button>
      </div>
    </>
  )
}

export default Register