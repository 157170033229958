import Modal from "./Modal";
import Register from "./register";

export default function ModalContent({ onClose }) {
  return (
    <Modal onClose={onClose}>
      <div className={"mx-auto"}>
        <Register/>
      </div>
    </Modal>
  )
}