import ModalContent from "./ModalContent";
import useModal from "./ModalProvider";
const segmenter = new Intl.Segmenter("ja", { granularity: "grapheme" });

const S="あいうえおかきくけこさしすせそなにぬねのたちつてとはひふへほまみむめもやゆよわをんABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
const SecretBlock = ({ children }) => {
  const { openModal, closeModal } = useModal()
  return (
      <span
        onClick={() => openModal(<ModalContent onClose={closeModal}/>)}
        className={"mosaic"}>
        {Array.from(Array([...segmenter.segment(children)].length)).map(()=>S[Math.floor(Math.random()*S.length)]).join('')
        }
      </span>
)
}

export default SecretBlock