import React, { PropsWithChildren, ReactNode, createContext, useContext, useState } from 'react'
import { createPortal } from 'react-dom'

import Modal from './Modal'


const ModalContext = createContext({});

export const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState();
  const [className, setClassName] = useState('')

  const openModal = (content, className) => {
    setClassName(className ?? '')
    setContent(content)
    setIsOpen(true)
  }

  const closeModal = () => {
    setClassName('')
    setContent(null)
    setIsOpen(false)
  }

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        openModal,
        closeModal,
      }}
    >
      {children}
      {isOpen
        ? createPortal(
          <Modal onClose={closeModal} className={className}>
            {content}
          </Modal>,
          document.body,
        )
        : null}
    </ModalContext.Provider>
  )
}

const useModal = () => useContext(ModalContext)
export default useModal